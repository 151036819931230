import {
    handleUploadFile,
    handleUploadBase64File
} from '@/utils/tools/upLoad.js'
export function uploadVideoFile(file, callBack = (result) => {}, uploadProgress = (percent) => {}, cancelToken = '') {
    let url = "/api/material/Upload/uploadFile"
    return handleUploadFile(url, file, callBack, uploadProgress, cancelToken)
}
export function uploadPicFile(file, callBack = (result) => {}, uploadProgress = (percent) => {}, cancelToken = '') {
    let url = "/api/material/Upload/uploadFile"
    return handleUploadFile(url, file, callBack, uploadProgress, cancelToken)
}

export function uploadBase64File(base64) {
    let url = "/api/material/Upload/uploadFile"
    return handleUploadBase64File(url, base64)
}