import {
    ref,
    reactive
} from "vue";
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 14,
    },
};
//表单 数据
const formState = reactive({
    tagType: [],
    materialName: "",
    materialDesc: "",
    //video
    video_url: "",
    controls: false,
    visible: false,
    poster: "",
    poster_url: "",
    //pic
    pic_url: "",
    //file
    fileObj: null,
    extension: ''
});
const options = ref([]);
for (let i = 10; i < 36; i++) {
    const value = i.toString(36) + i;
    options.value.push({
        label: `Long Label: ${value}`,
        value,
    });
}
const formRef = ref();
let checkAge = async (_rule, value) => {
    if (!value) {
        return Promise.reject('Please input the age');
    }
    if (!Number.isInteger(value)) {
        return Promise.reject('Please input digits');
    } else {
        if (value < 18) {
            return Promise.reject('Age must be greater than 18');
        } else {
            return Promise.resolve();
        }
    }
};
let validatePass = async (_rule, value) => {
    if (value === '') {
        return Promise.reject('Please input the password');
    } else {
        if (formState.checkPass !== '') {
            formRef.value.validateFields('checkPass');
        }
        return Promise.resolve();
    }
};
let validatePass2 = async (_rule, value) => {
    if (value === '') {
        return Promise.reject('Please input the password again');
    } else if (value !== formState.pass) {
        return Promise.reject("Two inputs don't match!");
    } else {
        return Promise.resolve();
    }
};
const rules = {
    materialName: [{
        required: true,
        message: "请输入素材名称",
        trigger: "blur"

    }],
    checkPass: [{
        validator: validatePass2,
        trigger: 'change',
    }],
    age: [{
        validator: checkAge,
        trigger: 'change',
    }],
};
export {
    layout,
    formState,
    rules,
    options
}