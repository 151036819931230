const column = [
    {
        title: "项目名称",
        dataIndex: "projectName",
        sorter: true,
        width: "200px",
        key: "projectName",
    },
    {
        title: "创建人",
        dataIndex: "create_by",
        key: "create_by",
        // filters: [
        //   {
        //     text: "Male",
        //     value: "male",
        //   },
        //   {
        //     text: "Female",
        //     value: "female",
        //   },
        // ],
        width: "200px",
    },
]
export { column }