const State = {
    tagType: [],
    materialName: "",
    materialDesc: "",
    //video
    video_url: "",
    controls: false,
    visible: false,
    poster: "",
    poster_url: "",
    //pic
    pic_url: "",
    //file
    fileObj: null,
}
/**
* 清空表单数据
 * @param {Object} contentFormState 
 */
const cancelContent = (contentFormState) => {
    // 方式1
    // contentFormState.value = {
    //     tagType: [],
    //     pass: "",
    //     checkPass: "",
    //     age: undefined,
    // }
    // contentFormState = {
    //     tagType: [],
    //     pass: "",
    //     checkPass: "",
    //     age: undefined,
    // }

    // 方式2
    // contentFormState.tagType = [];
    // contentFormState.pass = "";
    // contentFormState.checkPass = "";

    // 将表单中的各项数据恢复初始值即可
    Object.assign(contentFormState, State)
}
export {
    cancelContent
} 