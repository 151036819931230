import request from '@/utils/request.js'
export function insertMaterial(data) {
    return request({
        'url': '/material/Material/hanldeAddMaterial',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}
// import { testGetMaterialList } from './testapi.js'
export function getMaterialList(data) {

    return request({
        'url': '/material/Material/getMaterialList',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}

/**
 * 获取分类list
 * @param {*} data 
 * @returns 
 */
export function getTagist(data) {

    return request({
        'url': '/material/Material/getTagist',
        headers: {
            isToken: true
        },
        'method': 'post',
        'data': data
    })
}

export function getUptoken(data) {

    return request({
        'url': '/material/Ststokentwo/getStsToken',
        headers: {
            isToken: true
        },
        'method': 'get',
        'data': data
    })
}