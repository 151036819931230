/**
 * 处理大文件上传
 * @param {Object} content
 * @param {function} callback
 */

import axios from "axios"

import config from '@/config'
// axios.defaults.baseurl=config.baseURL;
export function handleUploadFile(url, file, callBack, uploadProgress, cancelToken) {

    let formData = new FormData();
    let configs = {
        headers: {
            "Content-Type": "multipart/form-data",

            lang: "zh-CN",
        },
        onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                uploadProgress(percentCompleted);
            }
        },
        cancelToken
    };
    formData.append("file", file);
    formData.append("lang", 1); //1中文，2英文
    formData.append("type", 2); //1图片，2视频
    // return request({
    //     ...configs,
    //     url,
    //     data: formData
    // });
    return axios.post(url, formData, configs).then((res) => {
        callBack(res);
    })
}

/**
 * 将base64位图片转为formData上传
 * @param {String} base64
 */

import { convertBase64UrlToBlob } from "@/utils/tools/http.js";

export function handleUploadBase64File(url, base64) {
    //步骤1:将base64位图片转为formData上传格式
    let f = convertBase64UrlToBlob(base64); //data为裁剪后的base64位图片
    const fd = new FormData();
    fd.append("file", f, new Date().getTime() + ".jpg");
    return axios.post(
        url,
        fd
    )
}