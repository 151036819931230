<template>
  <div class="home">
    <div style="padding: 10px">
      <a-breadcrumb :style="{ margin: '16px 0' }">
        <a-breadcrumb-item>主页</a-breadcrumb-item>

        <a-breadcrumb-item>素材</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <upPicOrVideo
      @handleGetList="handleCreate"
      :modalVisible="visible"
      :upType="upType"
      :tagTypeList="tagTypeList"
      @onCancel="handleCancel"
      @onSubmit="handleSubmit"
    >
    </upPicOrVideo>

    <review ref="refreview"></review>
    <addProject ref="refaddProject"></addProject>
    <div class="tab-type">
      <a-card>
        <a-tabs
          v-model:activeKey="activeKey"
          type="card"
          @tabClick="handleTabClick"
        >
          <template #rightExtra>
            <a-button
              v-if="activeKey == 1"
              type="primary"
              @click="openUpModel(1)"
            >
              <template #icon>
                <CloudUploadOutlined />
              </template>
              上传视频
            </a-button>
            <a-button
              v-if="activeKey == 2"
              type="primary"
              @click="openUpModel(2)"
            >
              <template #icon>
                <CloudUploadOutlined />
              </template>
              上传图片
            </a-button>
          </template>
          <a-tab-pane key="1" tab="视 频">
            <div class="card-row">
              <div class="search-box">
                <div class="table-top-layout">
                  <a-form layout="inline">
                    <a-row :gutter="48">
                      <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                        <a-form-item
                          style="width: 100%"
                          :label-col="formItemLayout.labelCol"
                          :wrapper-col="formItemLayout.wrapperCol"
                          label="素材名称"
                        >
                          <a-input
                            placeholder="素材名称"
                            v-model:value="queryParam.material_name"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                        <a-form-item
                          style="width: 100%"
                          :label-col="formItemLayout.labelCol"
                          :wrapper-col="formItemLayout.wrapperCol"
                          label="分  类"
                        >
                          <a-select
                            v-model:value="queryParam.tagType"
                            placeholder="请选择"
                          >
                            <a-select-option
                              v-for="(item, index) in tagTypeList"
                              :key="index"
                              :value="item.id"
                              >{{ item.tagTypeName }}</a-select-option
                            >
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                        <a-form-item
                          style="width: 100%"
                          :label-col="formItemLayout.labelCol"
                          :wrapper-col="formItemLayout.wrapperCol"
                          label="上传日期"
                        >
                          <a-range-picker
                            placeholder="上传日期"
                            v-model:value="queryParam.upload_time"
                          />
                        </a-form-item>
                      </a-col>
                      <template v-if="advanced">
                        <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                          <a-form-item
                            style="width: 100%"
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="更新日期"
                          >
                            <a-range-picker
                              placeholder="更新日期"
                              v-model:value="queryParam.update_time"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                          <a-form-item
                            style="width: 100%"
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="素材尺寸"
                          >
                            <!-- <a-select v-model:value="queryParam.permission" placeholder="请选择">
                              <a-select-option value="1">公共</a-select-option>
                            </a-select> -->
                            <a-input
                              placeholder="素材尺寸"
                              v-model:value="queryParam.material_desc"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                          <a-form-item
                            style="width: 100%"
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="上传人"
                          >
                            <a-input
                              placeholder="上传人"
                              v-model:value="queryParam.upload_by"
                            />
                          </a-form-item>
                        </a-col>
                      </template>
                      <a-col
                        :md="(!advanced && 6) || 6"
                        :sm="24"
                        style="display: flex; justify-content: center"
                      >
                        <span
                          class="table-page-search-submitButtons"
                          :style="
                            (advanced && {
                              float: 'right',
                              overflow: 'hidden',
                            }) ||
                            {}
                          "
                        >
                          <a-button type="primary" @click="handleVideoSearch(1)"
                            >查询</a-button
                          >
                          <a-button
                            style="margin-left: 8px"
                            @click="handleVideoSearchReset"
                            >重置</a-button
                          >
                        </span>
                      </a-col>
                    </a-row>
                  </a-form>
                </div>
              </div>
              <div class="up-box"></div>
            </div>
            <tableComponent :columns="columnsVideo" :dataSource="dataSource">
              <template v-slot:tableBody="{ column, text, record }">
                <template v-if="column.dataIndex === 'action'">
                  <span>
                    <div style="padding: 5px">
                      <a-button
                        size="small"
                        @click="openReViewModel(record)"
                        type="primary"
                        >预览</a-button
                      >
                      <a-divider type="vertical" />
                      <a-button
                        size="small"
                        @click="downMaterial(record)"
                        type="primary"
                        >下载</a-button
                      >
                    </div>

                    <div style="padding: 5px">
                      <a @click="addToProject(record)">添加到</a>
                      <a-divider type="vertical" />
                      <a-dropdown :trigger="['click']">
                        <a class="ant-dropdown-link" @click.prevent>
                          更多
                          <DownOutlined />
                        </a>
                        <template #overlay>
                          <a-menu>
                            <!-- <a-menu-item key="0">
                              <a href="http://www.alipay.com/">编 辑</a>
                            </a-menu-item> -->
                            <a-menu-item key="1">
                              <a @click="hanldedelMaterial(record)">删 除</a>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </div>
                  </span>
                </template>
                <template v-else-if="column.dataIndex === 'tagType'">
                  <span>
                    <a-tag :color="'green'">
                      {{ record.tagType }}
                    </a-tag>
                  </span>
                </template>

                <template v-else-if="column.dataIndex === 'poster_url'">
                  <span>
                    <img
                      :src="text"
                      style="max-width: 200px; max-height: 60px"
                      alt=""
                    />
                  </span>
                </template>
                <template v-else-if="column.dataIndex === 'permission'">
                  <span> 公共 </span>
                  <!-- <span v-if="text == 1"> 公共 </span> -->
                </template>
                <template v-else-if="column.dataIndex === 'material_type'">
                  <span v-if="text == 1"> 视频 </span>
                  <span v-else-if="text == 2"> 图片 </span>
                </template>
              </template>
            </tableComponent>
          </a-tab-pane>
          <a-tab-pane key="2" tab="图 片">
            <div class="card-row">
              <div class="search-box">
                <div class="table-top-layout">
                  <a-form layout="inline">
                    <a-row :gutter="48">
                      <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                        <a-form-item
                          style="width: 100%"
                          :label-col="formItemLayout.labelCol"
                          :wrapper-col="formItemLayout.wrapperCol"
                          label="素材名称"
                        >
                          <a-input
                            placeholder="素材名称"
                            v-model:value="queryParam.material_name"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                        <a-form-item
                          style="width: 100%"
                          :label-col="formItemLayout.labelCol"
                          :wrapper-col="formItemLayout.wrapperCol"
                          label="分  类"
                        >
                          <a-select
                            v-model:value="queryParam.tagType"
                            placeholder="请选择"
                          >
                            <a-select-option
                              v-for="(item, index) in tagTypeList"
                              :key="index"
                              :value="item.id"
                              >{{ item.tagTypeName }}</a-select-option
                            >
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                        <a-form-item
                          style="width: 100%"
                          :label-col="formItemLayout.labelCol"
                          :wrapper-col="formItemLayout.wrapperCol"
                          label="上传日期"
                        >
                          <a-range-picker
                            placeholder="上传日期"
                            v-model:value="queryParam.upload_time"
                          />
                        </a-form-item>
                      </a-col>
                      <template v-if="advanced">
                        <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                          <a-form-item
                            style="width: 100%"
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="更新日期"
                          >
                            <a-range-picker
                              placeholder="更新日期"
                              v-model:value="queryParam.update_time"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                          <a-form-item
                            style="width: 100%"
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="素材尺寸"
                          >
                            <!-- <a-select v-model:value="queryParam.permission" placeholder="请选择">
                              <a-select-option value="0">公共</a-select-option>
                            </a-select> -->
                            <a-input
                              placeholder="素材尺寸"
                              v-model:value="queryParam.material_desc"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24" style="margin-bottom: 15px">
                          <a-form-item
                            style="width: 100%"
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="上传人"
                          >
                            <a-input
                              placeholder="上传人"
                              v-model:value="queryParam.upload_by"
                            />
                          </a-form-item>
                        </a-col>
                      </template>
                      <a-col
                        :md="(!advanced && 6) || 6"
                        :sm="24"
                        style="display: flex; justify-content: center"
                      >
                        <span
                          class="table-page-search-submitButtons"
                          :style="
                            (advanced && {
                              float: 'right',
                              overflow: 'hidden',
                            }) ||
                            {}
                          "
                        >
                          <a-button type="primary" @click="handlePicSearch"
                            >查询</a-button
                          >
                          <a-button
                            style="margin-left: 8px"
                            @click="handleVideoSearchReset"
                            >重置</a-button
                          >
                        </span>
                      </a-col>
                    </a-row>
                  </a-form>
                </div>
              </div>
              <div class="up-box"></div>
            </div>
            <tableComponent :columns="columnsPic" :dataSource="picDataSource">
              <template v-slot:tableBody="{ column, text, record }">
                <template v-if="column.dataIndex === 'action'">
                  <span>
                    <div style="padding: 5px">
                      <a-button
                        size="small"
                        @click="openReViewModel(record)"
                        type="primary"
                        >预览</a-button
                      >
                      <a-divider type="vertical" />
                      <a-button
                        size="small"
                        @click="downMaterial(record)"
                        type="primary"
                        >下载</a-button
                      >
                    </div>

                    <div style="padding: 5px">
                      <a @click="addToProject(record)">添加到</a>
                      <a-divider type="vertical" />
                      <a-dropdown :trigger="['click']">
                        <a class="ant-dropdown-link" @click.prevent>
                          更多
                          <DownOutlined />
                        </a>
                        <template #overlay>
                          <a-menu>
                            <!-- <a-menu-item key="0">
                              <a href="http://www.alipay.com/">编 辑</a>
                            </a-menu-item> -->
                            <a-menu-item key="1">
                              <a @click="hanldedelMaterial(record)">删 除</a>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </div>
                  </span>
                </template>
                <template v-else-if="column.dataIndex === 'tagType'">
                  <span>
                    <a-tag :color="'green'">
                      {{ record.tagType }}
                    </a-tag>
                  </span>
                </template>

                <template v-else-if="column.dataIndex === 'poster_url'">
                  <span>
                    <img
                      :src="text"
                      style="max-width: 200px; max-height: 60px"
                      alt=""
                    />
                  </span>
                </template>
                <template v-else-if="column.dataIndex === 'permission'">
                  <span> 公共 </span>
                  <!-- <span v-if="text == 1"> 公共 </span> -->
                </template>
                <template v-else-if="column.dataIndex === 'material_type'">
                  <span v-if="text == 1"> 视频 </span>
                  <span v-else-if="text == 2"> 图片 </span>
                </template>
              </template>
            </tableComponent>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import tableComponent from "./component/table/tableComponent.vue";
import { defineComponent, reactive, ref, toRaw, getCurrentInstance } from "vue";
import addProject from "./modal/addProject";
import { useStore } from "vuex";
import {
  PoweroffOutlined,
  CloudUploadOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import upPicOrVideo from "./component/upPicOrVideo";
import review from "./component/modal/review";
import { columnsVideo, columnsPic } from "./homeDataField.js";

import { getMaterialList, getTagist } from "@/api/material";
import { delMaterial } from "@/api/project";
import { load } from "@/utils/loading/load.js";
export default defineComponent({
  name: "Home",
  components: {
    tableComponent,
    PoweroffOutlined,
    CloudUploadOutlined,
    upPicOrVideo,
    review,
    DownOutlined,
    addProject,
  },
  data() {
    return {
      user: {
        username: "zeng",
        nickNmae: "zhi",
      },

      //查询展开
      advanced: true,
      //louyout
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      //table column

      loading: false,
      toggleSearchStatus: 1,
    };
  },
  setup() {
    // 获取实例
    const { proxy } = getCurrentInstance();
    //搜索条件
    const queryParam = reactive({
      material_name: "",
      tagType: null,
      update_time: null,
      upload_time: null,
      permission: null,
      upload_by: "",
      material_desc: "",
    });
    //分类列表
    const refreview = ref(null);
    const tagTypeList = ref([]);
    const refaddProject = ref(null);
    const visible = ref(false);
    const reviewVisible = ref(false);

    const upType = ref(1);
    const reviewType = ref(1);
    const sourceUrl = ref("");

    const dataSource = ref([]);
    const picDataSource = ref([]);
    //测试 store
    // const store = useStore();
    // console.log(toRaw(store.state.user.userInfo));
    /**
     *打开上传modal
     */
    const openUpModel = (type) => {
      visible.value = true;
      upType.value = type;
    };
    const openReViewModel = (item) => {
      // console.log(item.material_type);
      // reviewVisible.value = true;
      // sourceUrl.value = item.video_url;
      // reviewType.value = item.material_type;
      refreview.value.handleOpenModel(true, item);
    };
    /**
     *处理关闭上传modal
     */
    const handleCancel = () => {
      visible.value = false;
    };
    const handleReViewCancel = () => {
      visible.value = false;
    };
    const handleSubmit = () => {};

    /**
     * 获取视频素材
     */
    const handleGetVideoMaterialList = async () => {
      load.show("加载中");
      let res = await getMaterialList({ material_type: 1 });
      console.log(res);
      dataSource.value = res.data;
      setTimeout(() => {
        load.hide();
      }, 500);
    };

    /**
     * 获取图片素材
     */
    const handleGetPicMaterialList = async () => {
      load.show("加载中");
      let res = await getMaterialList({ material_type: 2 });
      console.log(res);
      picDataSource.value = res.data;
      setTimeout(() => {
        load.hide();
      }, 500);
    };
    const handleCreate = () => {
      handleGetVideoMaterialList();
      handleGetPicMaterialList();
    };
    handleCreate();
    //处理tab 点击
    const handleTabClick = (e) => {
      if (e == 1) {
        handleGetVideoMaterialList();
      } else if (e == 2) {
        handleGetPicMaterialList();
      }
    };
    const handleVideoSearch = async () => {
      let res = await getMaterialList({ material_type: 1, ...queryParam });

      dataSource.value = res.data;
    };
    const handlePicSearch = async () => {
      let res = await getMaterialList({ material_type: 2, ...queryParam });

      picDataSource.value = res.data;
    };
    //换取分类列表
    const handleGetTagist = () => {
      getTagist({}).then((res) => {
        tagTypeList.value = res.data;
      });
    };
    const handleVideoSearchReset = () => {
      queryParam.material_name = "";
      queryParam.tagType = null;
      queryParam.update_time = null;
      queryParam.upload_time = null;
      queryParam.permission = "";
      queryParam.upload_by = "";

      handleVideoSearch();
    };
    //初始化
    const intit = () => {
      handleGetTagist();
    };
    intit();
    const addToProject = (record) => {
      refaddProject.value.handleOpenModel(true, record);
    };
    const hanldedelMaterial = (record) => {
      delMaterial({ id: record.id }).then((res) => {
        if (res.code == "ok") {
          proxy.$Message.success(res.msg);
          handleCreate();
        } else {
          proxy.$Message.error(res.msg);
        }
      });
    };
    const downFileUrl = (img, material_name) => {
      //var img = "后台返回的文件地址"; //注意需要拼接域名访问哦~
      const url = img;
      let xmlhttp = new XMLHttpRequest();
      xmlhttp.open("GET", url, true);
      xmlhttp.responseType = "blob";
      xmlhttp.onload = function () {
        if (this.status == 200) {
          const blob = this.response;
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            material_name + url.substring(url.lastIndexOf("."), url.length);
          //此写法兼容可火狐浏览器
          document.body.appendChild(link);
          const evt = document.createEvent("MouseEvents");
          evt.initEvent("click", false, false);
          link.dispatchEvent(evt);
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }
      };
      xmlhttp.send();
    };
    const download = (url, name) => {
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.target = "_blank";
      a.click();
      a.remove();
    };
    const downMaterial = (record) => {
      if (record.material_type == 1) {
        download(record.video_url, record.material_name);
      } else {
        downFileUrl(record.pic_url, record.material_name);
      }
    };
    return {
      //ref
      refreview,
      activeKey: ref("1"),
      //data
      queryParam,
      tagTypeList,
      upType,
      reviewType,
      visible,
      reviewVisible,
      dataSource,
      picDataSource,
      columnsVideo,
      columnsPic,
      sourceUrl,
      //methods

      openUpModel,
      handleCancel,
      handleSubmit,
      handleTabClick,
      openReViewModel,
      handleReViewCancel,
      handleVideoSearch,
      handleVideoSearchReset,
      handleCreate,
      addToProject,
      refaddProject,
      hanldedelMaterial,
      downMaterial,
      handlePicSearch,
    };
  },
});
</script>
<style scoped lang="less">
.card-row {
  padding: 10px;

  .search-box {
    display: inline-block;
  }

  .up-box {
    display: inline-block;
    float: right;
  }
}

.tab-type {
}
</style>