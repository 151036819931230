import axios from "axios";
/**
 * 文件下载
 * @param {*} obj  参数格式{url: '', fileName: ''}
 */
export function downloadFile(obj) { // 参数格式{url: '', fileName: ''}
    axios.get(obj.url, { responseType: 'blob' }).then((res) => {
        const { data, headers } = res
        const fileName = obj.fileName
        // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
        const blob = new Blob([data], { type: headers['content-type'] })
        let dom = document.createElement('a')
        let url = window.URL.createObjectURL(blob)
        dom.href = url
        dom.download = decodeURI(fileName)
        dom.style.display = 'none'
        document.body.appendChild(dom)
        dom.click()
        dom.parentNode.removeChild(dom)
        window.URL.revokeObjectURL(url)
    }).catch((err) => { })
}

//base64转Blob
export function convertBase64UrlToBlob(urlData){
    //去掉url的头，并转换为byte 
    var split = urlData.split(',');
    var bytes=window.atob(split[1]);        
    //处理异常,将ascii码小于0的转换为大于0  
    var ab = new ArrayBuffer(bytes.length);  
    var ia = new Uint8Array(ab);  
    for (var i = 0; i < bytes.length; i++) {  
      ia[i] = bytes.charCodeAt(i);  
    }
    return new Blob( [ab] , {type : split[0]});  
  } 
