<template>
  <customModel
    ref="myModal"
    title="添加到项目"
    @handleOk="handdleOk"
    @handleCancel="handleCancel"
    :modalVisible="visible"
  >
    <div>
      <a-table
        bordered
        :rowKey="
          (record) => {
            return record.id;
          }
        "
        :row-selection="{
          type: 'radio',
          columnTitle: '选择',
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectVideoChange,
        }"
        :columns="column"
        :data-source="datasource"
      >
        <template #bodyCell="{ column, text }">
          <template v-if="column.dataIndex === 'name'">
            <a>{{ text }}</a>
          </template>
        </template>
      </a-table>
    </div>
    <div style="text-align: center">
      <a-button type="primary" @click="handleAddToProject">确定</a-button>
    </div>
  </customModel>
</template>
<script >
import customModel from "@/components/modelComponent/customModel.vue";
import { addToProjec, getProjectList } from "@/api/project";
import { column } from "./column";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  defineExpose,
  watch,
  toRefs,
  reactive,
} from "vue";

export default defineComponent({
  components: {
    customModel,
  },
  setup(props, { emit }) {
    // 获取实例
    const { proxy } = getCurrentInstance();
    //ref
    const myModal = ref(null);
    const formRef = ref(null);
    const datasource = ref([]);
    //state
    const stateData = reactive({ visible: false, curMaterial: null });
    const formState = reactive({
      webname: "",
      webdomin: "",
      web_url: "",
    });
    const selectedRowKeys = ref(null);
    const selectedRow = ref(null);
    const layout = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const rules = reactive({
      projectName: [
        { required: true, message: "请输入项目名称", trigger: "blur" },
      ],
      permission: [{ required: false }],
    });
    const visible = ref(false);

    const handleOpenModel = (value, record) => {
      visible.value = value;
      stateData.curMaterial = record.id;
    };
    const openModelBymethod = (value) => {
      myModal.value.openModal({ visible: value, title: "添加项目" });
    };
    const handleCancel = (value) => {
      visible.value = value;
      resetForm();
    };
    const handdleOk = () => {};
    /**
     * 添加站点
     */
    const handleFinish = (values) => {
      // console.log(values, formState);
      addProject(formState)
        .then((res) => {
          if (res.code == "ok") {
            proxy.$Message.success(res.msg);
            emit("handleGetList");
          } else {
            proxy.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          handleCancel(false);
        });
    };
    const handleFinishFailed = (errors) => {
      console.log(errors);
    };
    const resetForm = () => {
      selectedRow.value = [];
      selectedRowKeys.value = null;
    };
    const handleValidate = (...args) => {
      console.log(args);
    };
    const onSelectVideoChange = (key, row) => {
      selectedRow.value = row;
      selectedRowKeys.value = key;
    };
    const handleProjectList = async () => {
      let res = await getProjectList();

      datasource.value = res.data;
      // setTimeout(() => {
      //   load.hide();
      // }, 2000);
    };
    const handleAddToProject = () => {
      if (selectedRow.value.length <= 0) {
        alert("请选择项目");
        return;
      }
      addToProjec({
        materialId: stateData.curMaterial,
        projectId: selectedRow.value[0].id,
      })
        .then((res) => {
          if (res.code == "ok") {
            proxy.$Message.success(res.msg);
            // emit("handleGetList");
          } else {
            proxy.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          handleCancel(false);
        });
    };
    handleProjectList();
    return {
      //   ...toRefs(stateData),
      //state

      visible,
      formState,
      layout,
      rules,
      //ref
      formRef,
      myModal,
      //methods
      handdleOk,
      openModelBymethod,
      handleOpenModel,
      handleCancel,

      handleFinish,
      handleFinishFailed,
      resetForm,
      handleValidate,
      //--------
      column,
      datasource,
      selectedRowKeys,
      onSelectVideoChange,
      handleAddToProject,
    };
  },
});
</script>
