<template>
  <div>
    <a-modal :footer="null" :maskClosable="false" v-model:visible="visible" :title="title" @ok="handleOk"
      @cancel="handleCancel">
      <!-- <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button type="primary" html-type="submit">提交</a-button>


      </template> -->
      <div class="modal-body">
        <a-form ref="formRef" name="custom-validation" :model="formState" :rules="rules" v-bind="layout"
          @finish="handleOk">
          <a-form-item label="素材名称" name="materialName">
            <a-input v-model:value="formState.materialName" type="text" autocomplete="off" />
          </a-form-item>
          <a-form-item label="素材分类" name="tagType">
            <a-select labelInValue v-model:value="formState.tagType" placeholder="请选择">
              <a-select-option v-for="(item, index) in tagTypeList" :key="index" :value="item.id">{{ item.tagTypeName
              }}</a-select-option>
            </a-select>
            <!-- <a-select v-model:value="formState.tagType" mode="multiple" style="width: 100%" placeholder="选择素材分类"
              max-tag-count="responsive" :options="options"></a-select> -->
          </a-form-item>

          <a-form-item has-feedback label="尺寸大小" name="materialDesc">
            <a-input v-model:value="formState.materialDesc" placeholder="如120*100" type="text" autocomplete="off" />
          </a-form-item>
          <!-- <a-form-item has-feedback label="上传图" name="materialDesc">
            <upFile @uploadDone="handleUploadDone" />
          </a-form-item> -->

          <a-form-item v-if="upType == 2" has-feedback label="上传图片" name="materialDesc">
            <div class="pic-item">
              <div v-show="formState.visible" class="pic-warp">
                <img id="upPicid" :src="formState.pic_url" />
              </div>
              <div class="upload-select-picture-card" @click="handleSelecPic">
                <div class="picture-card">
                  <plus-outlined />
                  <div style="margin-top: 8px">Upload</div>
                  <input ref="refuploadPic" type="file" id="filepickerPic" accept=".jpg,.png,.bmp,.jpeg,.psd,.zip"
                    style="display: none" @change="choosePicInput" class="uploadPic_input" />
                </div>
              </div>

            </div>
            <div>支持jpg,png,psd,zip文件</div>
          </a-form-item>
          <a-form-item v-if="upType == 1" k label="选择视频" name="poster">
            <div class="video-item">
              <div v-show="formState.visible" class="video-warp">
                <video id="video" class="videoid" poster="封面图路径" :src="formState.video_url" :controls="formState.controls"
                  x5-playsinline="" playsinline="" webkit-playsinline preload="auto"></video>
              </div>
              <div class="upload-select-picture-card" @click="handleSelecVideo">
                <div class="picture-card">
                  <plus-outlined />
                  <div style="margin-top: 8px">Upload</div>
                  <input ref="refupload" type="file" id="filepicker" accept="video/mp4" style="display: none"
                    @change="chooseVideoInput" class="uploadVideo_input" />
                </div>
              </div>
            </div>
          </a-form-item>

          <a-form-item has-feedback label="封面">
            <div class="poster-item">
              <img :src="formState.poster" alt="" />
            </div>
          </a-form-item>

          <!-- <a-form-item has-feedback label="Password" name="pass">
            <a-input v-model:value="formState.pass" type="password" autocomplete="off" />
          </a-form-item>
          <a-form-item has-feedback label="Confirm" name="checkPass">
            <a-input v-model:value="formState.checkPass" type="password" autocomplete="off" />
          </a-form-item>
          <a-form-item has-feedback label="Age" name="age">
            <a-input-number v-model:value="formState.age" />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" html-type="submit">Submit</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">Reset</a-button>
          </a-form-item> -->
          <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" html-type="submit">提交</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
//test

import { login } from "@/api/user.js";
import { insertMaterial } from "@/api/material.js";
import upFile from "./upFile";
import {
  uploadVideoFile,
  uploadPicFile,
  uploadBase64File,
} from "@/api/upLoad.js";
import { getUptoken } from "@/api/material.js";

//test -end
import {
  defineComponent,
  ref,
  toRefs,
  computed,
  reactive,
  getCurrentInstance,
  toRaw,
} from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import axios from "axios";
import { layout, formState, options } from "./upPicOrVideoData";
import { cancelContent } from "./upPicOrVideoMethods";
import { useStore } from "vuex";
import OSS from "ali-oss";
export default defineComponent({
  components: {
    PlusOutlined,
    upFile,
  },
  props: {
    modalVisible: {
      default: false,
      type: Boolean,
    },
    upType: Number,
    tagTypeList: {
      default: [],
      type: Array,
    },
  },
  setup(props, content) {
    const { proxy } = getCurrentInstance();
    const visible = ref(false);
    const curFile = ref(null);
    const loading = ref(false);
    const maxTagCount = ref(2);
    const formRef = ref(null);
    const refupload = ref(null);
    const refuploadPic = ref(null);
    const videoState = reactive({
      video_url: "",
      controls: false,
      visible: false,
      poster: "",
    });
    const rules = reactive({
      materialName: [
        {
          required: true,
          message: "请输入素材名称",
          trigger: "blur",
        },
      ],
      tagType: [{ required: true, message: "请选择素材分类", trigger: "blur" }],
      poster: [{ required: true, message: "请选择文件", trigger: "change" }],
    });
    var { modalVisible, upType } = toRefs(props);
    const store = useStore();
    //选择图片
    const choosePicInput = (event) => {
      var files = document.getElementById("filepickerPic").files[0];
      var parts = files.name.split(".");
      var extension = parts[parts.length - 1];
      formState.extension = extension;
      var url = URL.createObjectURL(files);
      formState.fileObj = files;
      formState.pic_url = url;
      formState.visible = true;
      getPicPoster();
      curFile.value = files;
    };
    /**
     * 获取图片封面
     */
    const getPicPoster = () => {
      setTimeout(() => {
        let picObj = document.getElementById("upPicid");
        let canvas = document.createElement("canvas");
        //canvas的尺寸和图片一样
        var width = picObj.offsetWidth; // 返回元素的总宽度
        var height = picObj.offsetHeight;
        canvas.width = width; //画布大小，默认为视频宽高
        canvas.height = height;
        canvas.getContext("2d").drawImage(picObj, 0, 0, width, height); //绘制canvas
        let dataURL = canvas.toDataURL("image/png"); //转换为base64
        formState.poster = dataURL;
        uploadBase64File(dataURL)
          .then((res) => {
            formState.poster_url = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
        // const blob = Utils.transformBase64ToBlob(dataURL);
      }, 1000);
    };
    /**
     * 获取图片封面
     */
    const getVideoPoster = () => {
      setTimeout(() => {
        let video = document.getElementById("video");
        let canvas = document.createElement("canvas");
        //canvas的尺寸和图片一样
        var width = video.offsetWidth; // 返回元素的总宽度
        var height = video.offsetHeight;
        canvas.width = width; //画布大小，默认为视频宽高
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
        let dataURL = canvas.toDataURL("image/png"); //转换为base64
        formState.poster = dataURL;
        uploadBase64File(dataURL)
          .then((res) => {
            formState.poster_url = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          });
        // const blob = Utils.transformBase64ToBlob(dataURL);
      }, 1000);
    };
    //选择视频
    const chooseVideoInput = (event) => {
      var files = document.getElementById("filepicker").files[0];
      var parts = files.name.split(".");
      var extension = parts[parts.length - 1];
      formState.extension = extension;
      formState.fileObj = files;
      var url = URL.createObjectURL(files);
      formState.video_url = url;
      formState.visible = true;
      getVideoPoster();
      let file = event.target.files[0];
      curFile.value = file;
    };
    //处理上传视频
    const handlePush = (e) => {
      let file = e.target.files[0];
      let formData = new FormData();
      let configs = {
        headers: {
          "Content-Type": "multipart/form-data",

          lang: "zh-CN",
        },
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent);
          if (progressEvent.lengthComputable) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // console.log("进度：", percentCompleted);
            // upIngVideoArr[itemkey].transcodProgress = percentCompleted;
            // upIngVideoArr[itemkey];
            console.log(percentCompleted);
          }
        },
        // cancelToken: axios.CancelToken.source(),
      };
      formData.append("file", file);
      formData.append("lang", 1); //1中文，2英文
      formData.append("type", 2); //1图片，2视频
      axios
        .post("/api/material/Upload/uploadVideoFileFP", formData, configs)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const handleSelecPic = () => {
      refuploadPic.value.dispatchEvent(new MouseEvent("click"));
    };
    const handleSelecVideo = () => {
      // 获取当前实例
      // let that = getCurrentInstance();
      // console.log(that);
      refupload.value.dispatchEvent(new MouseEvent("click"));
    };
    /**
     *处理 关闭modal
     */
    const handleCancel = () => {
      content.emit("onCancel");
      formRef.value.clearValidate();
      formRef.value.resetFields();
      //清空表单方案
      cancelContent(formState);
    };
    /**
     * 处理点击提交
     */
    const handleOk = () => {
      if (upType.value == 1) {
        if (formState.poster_url == "") {
          return;
        }
        content.emit("onCancel");
        customRequest();
      } else {
        customPicRequest();
      }
    };
    //methods
    /**
     * 处理标题
     */
    var title = computed(() => {
      if (upType.value == 1) {
        return "上传视频";
      } else if (upType.value == 2) {
        return "上传图片";
      } else {
        return "";
      }
    });
    //测试函数
    const handleTest = () => {
      uploadVideoFile(formState.fileObj, function (res) {
        console.log(res);
      });
      // login({ user: 1231 })
      //   .then((res) => {})
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // load.show("登录中...");
    };
    const handleUploadDone = (res) => {
      console.log(res);
    };
    const createOssClient = () => {
      return new Promise((resolve, reject) => {
        const client = new OSS({
          region: "oss-cn-hangzhou.aliyuncs.com", // 请设置成你的
          accessKeyId: "LTAI5tFbXjUHmZrFwwKhEtaJ", // 请设置成你的
          accessKeySecret: "jVluHiBWBbwQzMbxP5thIMcY6JjxAq", // 请设置成你的
          bucket: "yuyuantang-sc", // 请设置成你的
          secure: true, // 上传链接返回支持https
        });
        // 'AccessKeyID'      => 'LTAI5tAwxjMbvJ5Zm7bun7Yw',
        // 'AccessKeySecret'  => 'iAqX3DGEdpescaOZXLLorl2tUJKbkm',
        // 'EndPoint'   => 'oss-cn-hangzhou.aliyuncs.com',
        // 'Bucket'     => 'yuyuantang-sc',
        resolve(client);
      });
    };
    const fileInputChange = (e) => {
      console.log(e);
    };
    const customRequest = async (e) => {
      let tk = await getUptoken({});

      const client = new OSS({
        region: "cn-hangzhou",
        accessKeyId: tk.AccessKeyId,
        accessKeySecret: tk.AccessKeySecret,
        stsToken: tk.SecurityToken,
        bucket: "yuyuantang-sc",
        secure: true,
        endpoint: "oss-cn-hangzhou.aliyuncs.com",
      });
      let file = curFile.value;
      let timestamp = Date.parse(new Date()) / 1000;
      //文件名可以自定义
      const fileName = `${timestamp}_${file.name}`;

      store.dispatch("setUploadList", formState);
      client
        .multipartUpload(fileName, file, {
          //防止覆盖
          headers: { "x-oss-forbid-overwrite": true },
          //上传进度
          progress: (p, cpt, res) => {
            // this.item.fileUploadPercent = Math.round(p * 100);
            console.log(p * 100 + "%");
          },
        })
        // console.log(res);
        .then((res) => {
          //获取上传文件的oss地址
          store.state.account.uploadList.pop();
          formState.video_url = res.res.requestUrls[0].split("?")[0];
          let sendData = {
            materialUid: 1,
            materialName: formState.materialName,
            materialDesc: formState.materialDesc,
            tagType: formState.tagType.label[0].children,
            tagType_id: formState.tagType.value,
            poster_url: formState.poster_url,
            pic_url: "",
            video_url: formState.video_url,
            poster: "",
            upLoadProgress: 0,
            isUpload: false,
            type: 1,
            extension: formState.extension
          };
          insertMaterial(sendData)
            .then((res) => {
              if (res.code == "ok") {
                proxy.$Message.success(res.msg);
                content.emit("handleGetList");
              } else {
                proxy.$Message.error(res.msg);
              }
              handleCancel();
            })
            .catch((res) => {
              // proxy.$Message.error("上传失败,请重新上传");

              handleCancel();
            });
        })
        .catch((error) => {
          // store.state.account.uploadList.pop()
          // proxy.$Message.error('上传失败,请重新上传');
        });
    };
    const customPicRequest = async () => {
      let tk = await getUptoken({});

      const client = new OSS({
        region: "cn-hangzhou",
        accessKeyId: tk.AccessKeyId,
        accessKeySecret: tk.AccessKeySecret,
        stsToken: tk.SecurityToken,
        bucket: "yuyuantang-sc",
        secure: true,
        endpoint: "oss-cn-hangzhou.aliyuncs.com",
      });
      let file = curFile.value;
      console.log(file);
      let timestamp = Date.parse(new Date()) / 1000;
      //文件名可以自定义
      const fileName = `${timestamp}_${file.name}`;

      store.dispatch("setUploadList", formState);
      client
        .multipartUpload(fileName, file, {
          //防止覆盖
          headers: { "x-oss-forbid-overwrite": true },
          //上传进度
          progress: (p, cpt, res) => {
            // this.item.fileUploadPercent = Math.round(p * 100);
            console.log(p * 100 + "%");
          },
        })
        // console.log(res);
        .then((res) => {
          //获取上传文件的oss地址
          store.state.account.uploadList.pop();
          formState.pic_url = res.res.requestUrls[0].split("?")[0];
          let sendData = {
            materialUid: 1,
            materialName: formState.materialName,
            materialDesc: formState.materialDesc,
            tagType: formState.tagType.label[0].children,
            tagType_id: formState.tagType.value,
            poster_url: formState.poster_url,
            pic_url: formState.pic_url,
            video_url: formState.video_url,
            poster: "",
            upLoadProgress: 0,
            isUpload: false,
            type: 2,
            extension: formState.extension
          };
          insertMaterial(sendData)
            .then((res) => {
              if (res.code == "ok") {
                proxy.$Message.success(res.msg);
                content.emit("handleGetList");
              } else {
                proxy.$Message.error(res.msg);
              }
              handleCancel();
            })
            .catch((res) => {
              // proxy.$Message.error("上传失败,请重新上传");

              handleCancel();
            });
        })
        .catch((error) => {
          // store.state.account.uploadList.pop()
          // proxy.$Message.error('上传失败,请重新上传');
        });
    };

    return {
      //data
      formState,
      rules,
      options,
      maxTagCount,
      visible: modalVisible,
      loading,
      title,
      layout,
      videoState,

      //ref
      formRef,
      refupload,
      refuploadPic,
      //methods
      handleOk,
      handleCancel,
      handleSelecVideo,
      handleSelecPic,
      chooseVideoInput,
      choosePicInput,
      handleTest,
      handleUploadDone,
      fileInputChange,
      customRequest,
    };
  },
});
</script>
<style lang="less" scoped>
.upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;

  .picture-card {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.video-item {
  display: flex;

  .video-warp {
    margin-right: 10px;

    video {
      max-width: 102px;
      max-height: 102px;
    }
  }
}

.pic-item {
  display: flex;

  .pic-warp {
    margin-right: 10px;

    img {
      max-width: 102px;
      max-height: 102px;
    }
  }
}
</style>

